import {ButtonHTMLAttributes} from 'react';

export default function Button(
  {
    type = 'button',
    className = '',
    disabled,
    children,
    ...props
  }: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type={type}
      className={
        `
        block 
        rounded-md 
        bg-emerald-600 
        px-3 
        py-2 
        text-center 
        text-sm 
        font-semibold 
        text-white 
        shadow-sm 
        hover:bg-emerald-500 
        focus-visible:outline 
        focus-visible:outline-2 
        focus-visible:outline-offset-2 
        focus-visible:outline-slate-600 ${
          disabled && 'opacity-25'
        } ` + className
      }
      disabled={disabled}
    >
      {children}
    </button>
  );
}
